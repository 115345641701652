<template>
  <section class="header_m">
    <div class="contain">
      <span
        class="iconfont iconlogo"
        style="color=red;"
        @mouseup="toPath('Home')"
      >
      </span>
      <span
        class="iconfont iconnav_more"
        @click="drapBoxShowFlag = !drapBoxShowFlag"
      ></span>
      <div class="drapbox" v-show="drapBoxShowFlag" ref="drapBoxDOM">
        <span
          class="iconfont iconcela_delete"
          @mouseup="drapBoxShowFlag = false"
        ></span>
        <div class="item_box">
          <span
            class="item"
            v-for="value of navList"
            :key="value.index"
            @mouseup="toPath(value.path)"
            :style="navActiveFont(value.index)"
          >
            {{ value.name }}
          </span>
        </div>
        <div class="translate_box">
          <span @click="i18nZH()">中文</span>
          <span>|</span>
          <span @click="i18nEN()">English</span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
   inject: ["reload"],
  data() {
    return {
      drapBoxShowFlag: false,
      navList: [
        {
          index: 0,
          name: this.$t("PC.components.FooterC.ljcr45"),
          path: "Home"
        },
        {
          index: 1,
          name: this.$t("PC.components.FooterC.yl2det"),
          path: "Product"
        },
        {
          index: 2,
          name: this.$t("PC.components.FooterC.av935f"),
          path: "Solution"
        },
                      {
          index: 3,
          name: this.$t("PC.components.FooterC.hyyc12"),
          path: "Agendas",
        },
        {
          index: 4,
          name: this.$t("PC.components.FooterC.yq5vzh"),
          path: "Service"
        },
        {
          index: 5,
          name: this.$t("PC.components.FooterC.3yoxbi"),
          path: "News"
        },
        {
          index: 6,
          name: this.$t("PC.components.FooterC.z4k0gm"),
          path: "About"
        }
      ]
    };
  },
  mounted() {},
  methods: {
    i18nEN() {
      if (this.$i18n.locale != "en") {
        this.$i18n.locale = "en";
        window.localStorage.setItem("i18n", this.$i18n.locale);
        this.reload();
      }
    },
    i18nZH() {
      if (this.$i18n.locale != "zh") {
        this.$i18n.locale = "zh";
        window.localStorage.setItem("i18n", this.$i18n.locale);
        this.reload();
      }
    },
    toPath(path) {
      this.$router.push({
        name: path
      });
      setTimeout(() => {
        this.drapBoxShowFlag = false;
      }, 200);
    },
    navActiveFont(index) {
      if (this.$route.meta.index == index) {
        return "color: #003299;font-weight: 600;";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.header_m {
  position: fixed;
  top: 0;
  width: 100%;
  height: 48px;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(184, 188, 197, 0.28);
  box-sizing: border-box;
  padding: 0 20px;
  color: #003299;

  .contain {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .iconfont.iconlogo {
      font-size: 32px;
    }

    .iconnav_more {
      font-size: 24px;
    }

    .drapbox {
      position: fixed;
      right: 0;
      top: 0px;
      width: 230px;
      background-color: #fff;
      box-shadow: -3px 2px 6px 0px rgba(184, 188, 197, 0.28);
      height: 100%;

      .iconcela_delete {
        position: absolute;
        right: 12px;
        top: 3px;
        font-size: 20px;
        padding: 10px;
        color: #333;
      }

      .item_box {
        height: 332px;
        margin: 80px 0 0 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .item {
          width: 155px;
          font-size: 16px;
          color: #333;
          line-height: 22px;
        }
      }

      .translate_box {
        width: 100%;
        position: absolute;
        bottom: 16px;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 70px 0 40px;

        span {
          font-size: 16px;
          color: #333;
          line-height: 22px;
        }
      }
    }
  }
}
</style>
